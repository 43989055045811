import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../index.scss"
import LeadershipConsulting from "../elements/LeadershipConsulting"

const IndexPage = props => {
  console.log(props.data)
  return (


  <Layout>
    <SEO title={`${props.data.contentfulPage.title}`} image={props.data.contentfulPage.headerImage.fluid.src} description={props.data.contentfulPage.description.description} lang='english'/>
    <LeadershipConsulting content={props} />
  </Layout>
)}

export const pageQuery = graphql`
         query {
           contentfulPage(url: { eq: "leadership-development" }) {
              description {
               description
             }
             headerImage {
               fluid {
                 src
               }
             }
             sections {
               sectionImage {
                 fluid {
                   src
                 }
                 title
               }
               sectionContent {
                 id
                 json
               }
               sectionListTitle
               sectionLists {
                 list {
                   json
                 }
                 icon
                 title
               }
               title
             }
             content {
               json
             }
             title
           }
         }
       `

export default IndexPage
