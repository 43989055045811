import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {
  FiWatch,
  FiTrendingUp,
  FiUsers,
  FiActivity,
  FiBarChart,
  FiGrid,
} from "react-icons/fi"

const Icon = ({icon}) => {
    let renderIcon

    switch (icon) {
      case "Blocks":
        renderIcon = <FiGrid />
        break

      case "Graph":
        renderIcon = <FiBarChart />
        break

      case "Users":
        renderIcon = <FiUsers />
        break

      case "Watch":
        renderIcon = <FiWatch />
        break

      case "Cardiograph":
        renderIcon = <FiActivity />
        break

      case "UpwardArrow":
        renderIcon = <FiTrendingUp />
        break
    }

    return renderIcon
}

const Section = ({section, even}) => {
    
    
    const {sectionContent, sectionImage, sectionListTitle, sectionLists, title} = section

    const Content = documentToReactComponents(sectionContent.json)
    return (
      <>
        {/* learn to lead better start */}

        <div className="rn-finding-us-area rn-finding-us bg_color--1">
          <div className="inner">
            <div className="content-wrapper">
              <div className={`content ${even ? "" : "left-content"}`}>
                <h4 className="theme-gradient">{title}</h4>
                {Content}
                <a className="rn-btn btn-white" href="/contact">
                  Get Started
                </a>
              </div>
            </div>
            <div
              className="thumbnail"
              style={{
                left: even ? "inherit" : "0",
              }}
            >
              <div className="image">
                <img
                  src={sectionImage.fluid.src}
                  alt="Finding Images"
                  width={even ? 1278 : 1000}
                />
              </div>
            </div>
          </div>
        </div>

        {/* learn to lead better end */}

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h3>{sectionListTitle}</h3>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {sectionLists.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">
                      <Icon icon={val.icon} />
                    </div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      {documentToReactComponents(val.list.json)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}
      </>
    )
}

export default Section