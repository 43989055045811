import React, { Component } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import PageHelmet from "../component/common/Helmet"
import Breadcrumb from "../elements/common/Breadcrumb"
import Footer from "../component/footer/Footer"
import Section from "./leadership/Section"

const contentOptions = {
  renderMark: {
    [MARKS.ITALIC]: text => (
      <>
        <span>{text}</span>
      </>
    ),
  },

  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="tilte">{children}</h2>
    ),
  },
}

class LeadershipConsulting extends Component {
  render() {

    const {
      title,
      headerImage,
      content,
      sections
    } = this.props.content.data.contentfulPage

    const MainImage = headerImage.fluid.src

    const MainContent = documentToReactComponents(content.json, contentOptions)

    let description =
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going."
    return (
      <React.Fragment>
        {/* Start Breadcrump Area */}
        <Breadcrumb title={title} />
        {/* End Breadcrump Area */}

        {/* Begin Banner */}
        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div
            className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
            style={{
              backgroundImage:
                `url(${MainImage})`,
              backgroundSize: "cover",
              backgroundPosition: "5% 50%",
              height: "700px",
            }}
          />
        </div>
        {/* End Slider Area   */}

        {/* End Banner */}

        {/* Start Content Area */}
        <div className="rn-columns-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-column">
                  {MainContent}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start 
        {/* End Content Area */}

        {sections.map( (section, id) => {
          return (<Section key={id} section={section} even={id % 2 === 0} />)
        })}

        <Footer />
      </React.Fragment>
    )
  }
}

export default LeadershipConsulting
